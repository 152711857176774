<template>
  <v-container fluid>
    <v-card outlined class="pa-5 mt-3">
      <v-toolbar flat>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          outlined
          dense
          class="shrink search"
          :class="$vuetify.breakpoint.xsOnly ? 'seachXs' : ''"
          :placeholder="$t('search')"
          :label="$t('search')"
          single-line
          hide-details
        />
        <v-spacer></v-spacer>
        <v-btn
          :v-if="$store.getters.isInRole(37)"
          color="primary"
          class="ma-1"
          elevation="0"
          to="/prescriptionGroupItems"
        >
          {{ $t("add") }}
          <v-icon> mdi-plus </v-icon>
        </v-btn>
      </v-toolbar>
      <data-table-component
        :headers="headers"
        :items="groups"
        :search="search"
        :loading="loading"
      />
      <confirm-dialog
        :openDialog="dialogDelete"
        :onClicked="deleteItemConfirm"
        :onClose="closeDelete"
      ></confirm-dialog>
    </v-card>
  </v-container>
</template>
<script>
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import DataTableComponent from "@/components/DataTableComponent.vue";
export default {
  components: { ConfirmDialog, DataTableComponent },
  data() {
    return {
      valid: true,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editingItem: null,
      editingItemIndex: -1,
      search: "",

      items: [],
      groups: [],
      editedItem: {
        groupName: "",
        items: [],
        note: "",
      },
      defaultItem: {
        groupName: "",
        items: [],
        note: "",
      },
      rules: [(value) => !!value || this.$t("ThisFieldIsRequired")],
    };
  },
  created() {
    this.refreshTable();

    this.$axios.get("PrescriptionItems").then((response) => {
      this.items = response.data.data;
    });
  },
  computed: {
    headers() {
      var list = [
        {
          text: this.$t("prescriptions.prescriptionsGroupName"),
          value: "groupName",
        },
        // { text: this.$t("prescriptions.items"), value: "items" },
        { text: this.$t("notes"), value: "note" },
      ];

      var actions = {
        text: "",
        value: "actions",
        templates: [],
      };

      if (this.$store.getters.isInRole(38)) {
        actions.templates.push({
          type: "btn",
          icon: "mdi-pencil",
          text: this.$t("edit"),
          click: this.editItem,
        });
      }
      if (this.$store.getters.isInRole(39)) {
        actions.templates.push({
          type: "btn",
          icon: "mdi-delete",
          text: this.$t("delete"),
          click: this.deleteItem,
          color: "red darken-2",
        });
      }
      list.push(actions);
      return list;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    edit(index, item) {
      if (!this.editing) {
        this.editingItem = item;
        this.editingItemIndex = index;
      } else {
        this.editingItem = null;
        this.editingItemIndex = -1;
      }
    },

    editItem(item) {
      this.editedIndex = this.groups.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.$router.push("/prescriptionGroupItems/" + item.guid);
      // this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.groups.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      var deleteItem = this.groups[this.editedIndex];

      this.$axios
        .delete("PrescriptionGroup/Delete?guid=" + deleteItem.guid)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }

          this.refreshTable();
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    refreshTable() {
      this.$axios.get("PrescriptionGroup").then((response) => {
        this.groups = response.data.data;
      });
    },

    save() {
      var val = this.$refs.form.validate();
      if (val) {
        if (this.editedIndex > -1) {
          var updatedItem = this.groups[this.editedIndex];

          updatedItem.itemName = this.editedItem.groupName;
          updatedItem.note = this.editedItem.note;

          this.$axios
            .post("PrescriptionGroup/Update", updatedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }

              this.refreshTable();
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              this.refreshTable();
              console.log(e);
            });
          Object.assign(this.groups[this.editedIndex], this.editedItem);
        } else {
          this.$axios
            .post("PrescriptionGroup/Add", this.editedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }

              this.refreshTable();
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              this.refreshTable();
              console.log(e);
            });
        }
      }
    },
  },
};
</script>
<style scoped>
a {
  text-decoration: none;
}
</style>
